/* eslint-env browser */
// Libraries
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import amplitude from 'amplitude-js';
import $ from 'jquery';
import jqueryUjsInit from 'jquery-ujs';
import 'cocoon-js';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import * as Sentry from '@sentry/react';
import debounce from 'lodash/debounce';

// Utilities
import { renderMarkdown, readFileAsDataUrl } from 'common/utils/helpers';
import { logEvent } from 'common/utils/analytics';

// Enums
import Key from 'common/enums/Key';

// Scripts
import './admin/organization_activities/search-form';

Sentry.configureScope((scope) => {
  scope.setTag('user_mode', 'admin');
});

const envTest = /^v[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/;
let viteEnv = 'production';

if (!envTest.test(import.meta.env.VITE_CIVIL_SPACE_VERSION)) {
  viteEnv = 'staging-uat';
}

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: `rails-${import.meta.env.VITE_CIVIL_SPACE_VERSION}`,
  environment: viteEnv,
});

window.$ = $;
window.jQuery = $;
jqueryUjsInit($);

amplitude.getInstance().init(window.gon?.amplitudeApiKey, window.gon?.zencityUserId, {
  batchEvents: true,
  eventUploadPeriodMillis: 100,
});
window.amplitudeInstance = amplitude.getInstance();

if (window.gon?.zencityUserId) {
  window.plantrack?.identify?.(window.gon.zencityUserId);
}

// Log events triggered from rails
if (window.gon?.analyticsEvents) window.gon.analyticsEvents.forEach(logEvent);

const closeModal = () => document.dispatchEvent(new Event('admin-close-modal'));

const closeModalHotkeyListener = (event) => {
  if (event.key === Key.ESCAPE) closeModal();
};

document.addEventListener('admin-close-modal', () => {
  document.body.classList.remove(
    'content-modal-active',
    'question-modal-active',
    'support-modal-active',
    'switch-to-english-modal-active'
  );
  document.documentElement.classList.remove('no-scroll');
  window.removeEventListener('keydown', closeModalHotkeyListener);
});

$('.open-modal').click((event) => {
  event.preventDefault();
  const { modalName } = event.currentTarget.dataset;
  document.body.classList.add(`${modalName}-modal-active`);
  document.documentElement.classList.add('no-scroll');
  window.addEventListener('keydown', closeModalHotkeyListener);
});

$('.close-modal').click((event) => {
  event.preventDefault();
  if (event.originalEvent.isTrusted) closeModal();
});

$('.js-nav-toggle').click((event) => {
  event.preventDefault();
  $('body.admin').toggleClass('nav-active');
  $('html#cs-root').toggleClass('no-scroll');
});

$('.is-markdown').each((index, element) => {
  const $element = $(element);
  $element.html(renderMarkdown($element.html()));
});

$(document).on('change', 'input[type="file"]', async ({ target }) => {
  if (!target.files || target.files.length === 0) {
    return;
  }

  const $target = $(target);
  const $fileContainer = $target.closest('.file');
  const file = target.files[0];

  /* Update file name */
  $fileContainer.find('.file-name').html(file.name);

  /* Check for file size */
  const maxSizeBytes = $target.data('max-size');

  if (maxSizeBytes) {
    const $helpSizeText = $fileContainer.siblings('.help.size');

    if (file.size <= maxSizeBytes) {
      $helpSizeText.addClass('is-hidden');
    } else {
      $helpSizeText.addClass('is-danger').removeClass('is-hidden');
    }
  }

  /* Check for file type */
  const allowedTypes = $target.data('file-type') && $target.data('file-type').split(',');
  let validType = true;

  if (allowedTypes) {
    const $helpTypeText = $fileContainer.siblings('.help.type');

    if (allowedTypes.includes(file.type)) {
      $helpTypeText.addClass('is-hidden');
    } else {
      validType = false;
      const $currentImageTypeHelpContainer = $('#currentImageType');
      const typeErrorText = 'The current image type is "%TYPE%", which is not allowed.';

      $currentImageTypeHelpContainer.html(
        typeErrorText.replace('%TYPE%', file.type.replace('image/', ''))
      );
      $helpTypeText.removeClass('is-hidden');
    }
  }
  /* Show image preview */
  const $imageUploadContainer = $fileContainer.closest('.admin__image-upload');

  if ($imageUploadContainer.length) {
    /* Update file name */
    $imageUploadContainer
      .find('.admin__image-thumb-filename > .filename')
      .html(`<span>${file.name}</span>`);

    /* Update button text */
    $fileContainer.find('span.file-label').html('Replace image');

    const $preview = $imageUploadContainer.find('.admin__image-thumb-preview');

    if (validType) {
      $preview.find('img').addClass('is-loading');
      const dataUrl = await readFileAsDataUrl(file);
      const imgElement = document.createElement('img');
      const $img = $(imgElement).attr('src', dataUrl).attr('id', `preview_${target.name}`);

      $preview.empty().append($img);

      // allow image to be cropped
      window.cropper = window.cropper || {};
      const onCropEnd = (name) => {
        const fileInput = target;
        window.cropper[`preview_${name}`].getCroppedCanvas({}).toBlob(
          (blob) => {
            const newImage = new File([blob], fileInput?.files?.[0]?.name, {
              type: fileInput?.files?.[0]?.type,
              lastModified: new Date(),
            });
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(newImage);
            fileInput.files = dataTransfer.files;
          },
          fileInput?.files?.[0]?.type,
          0.8
        );
      };
      const aspectwidth = $fileContainer.attr('data-aspectwidth');
      const aspectheight = $fileContainer.attr('data-aspectheight');
      if (aspectwidth && aspectheight && aspectwidth !== '' && aspectheight !== '') {
        window.cropper[`preview_${target.name}`] = new Cropper(imgElement, {
          aspectRatio: parseFloat(aspectwidth) / parseFloat(aspectheight),
          autoCropArea: 1,
          zoomable: false,
          scalable: false,
          rotatable: false,
          movable: false,
          viewMode: 1,
          crop: debounce(() => {
            onCropEnd(target.name);
          }, 500),
        });
      } else {
        window.cropper[`preview_${target.name}`] = new Cropper(imgElement, {
          autoCropArea: 1,
          zoomable: false,
          scalable: false,
          rotatable: false,
          movable: false,
          viewMode: 1,
          crop: debounce(() => {
            onCropEnd(target.name);
          }, 500),
        });
      }

      $imageUploadContainer.find('.alt-text > .label').addClass('required');
      $imageUploadContainer.find('.alt-text input').attr('required', true);
    } else {
      $preview.empty();
    }
  }
});

$('[data-copy]').on('click', function (event) {
  event.preventDefault();
  const textArea = document.createElement('textarea');
  textArea.value = $(this).data('copy');
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  textArea.remove();
});

import('./admin/nav_drawer');

// test for sentry
// myUndefinedFunction();
